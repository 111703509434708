@use '@/styles/utils/mixins.scss' as *;

.headingWrapper {
  margin-bottom: 3.75rem;

  @include mob() {
    padding: 0 1.875rem;
  }
}

.container {
  display: flex;
  gap: 5rem;

  @include tab() {
    flex-direction: column;
  }

  @include mob() {
    background-color: $neutral02;
    padding: 3.75rem 2.1rem;
  }

  .offers {
    background-color: $neutral02;
    border-radius: 1.25rem;
    padding: 3.75rem 2.5rem;
    width: calc(50% - 5rem);

    .offerText {
      margin-bottom: 5rem;

      h4 {
        margin-bottom: 0.5rem;

        @include mob() {
          line-height: 1.8rem;
        }
      }
    }

    .offerList {
      display: flex;
      flex-direction: column;
      row-gap: 3.75rem;
    }

    @include tab() {
      width: 100%;
    }

    @include mob() {
      padding: 0;
    }
  }

  .quickLinkDivider {
    display: none;
    border-color: $primaryAlt !important;
    width: 100%;
    margin: 0;

    @include mob() {
      display: block;
    }
  }

  .formSection {
    width: calc(50% - 5rem);
    background-color: $neutral02;
    border-radius: 1.25rem;
    padding: 3.75rem 2.5rem;
    height: fit-content;

    .formText {
      margin-bottom: 5rem;

      h4 {
        margin-bottom: 0.5rem;

        @include mob() {
          line-height: 2.28rem;
        }
      }
    }

    @include tab() {
      width: 100%;
      padding: 3.75rem 0rem;
      padding-top: 0;
    }
  }

  .formSubmitted {
    border: 0.063rem solid $primaryAlt;
  }
}
